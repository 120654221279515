@font-face {
  font-family: 'GT America';
  src: url('../assets/fonts/GT\ America/GTAmerica-Regular.woff') format('woff'),
    url('../assets/fonts/GT\ America/GTAmerica-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('../assets/fonts/GT\ America/GTAmerica-RegularItalic.woff')
      format('woff'),
    url('../assets/fonts/GT\ America/GTAmerica-RegularItalic.woff2')
      format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GT America';
  src: url('../assets/fonts/GT\ America/GTAmerica-Medium.woff') format('woff'),
    url('../assets/fonts/GT\ America/GTAmerica-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('../assets/fonts/GT\ America/GTAmerica-MediumItalic.woff')
      format('woff'),
    url('../assets/fonts/GT\ America/GTAmerica-MediumItalic.woff2')
      format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'GT America';
  src: url('../assets/fonts/GT\ America/GTAmerica-Bold.woff') format('woff'),
    url('../assets/fonts/GT\ America/GTAmerica-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GT America';
  src: url('../assets/fonts/GT\ America/GTAmerica-BoldItalic.woff')
      format('woff'),
    url('../assets/fonts/GT\ America/GTAmerica-BoldItalic.woff2')
      format('woff2');
  font-weight: 700;
  font-style: italic;
}
